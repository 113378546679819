﻿.custom_line_chart_1 {
    fill: #8CE68C;
}

.custom_line_chart_2 {
    fill: #CFFFF6;
}

.custom_line_chart_3 {
    fill: #87CDF6;
}
