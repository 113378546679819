﻿.custom_horizontal_bar_chart_1 {
    fill: #3A8AD9;
}

.custom_horizontal_bar_chart_2 {
    fill: #4FA9F2;
}

.custom_horizontal_bar_chart_3 {
    fill: #87CDF6;
}

.custom_horizontal_bar_chart_4 {
    fill: #AEE7F8;
}

.custom_horizontal_bar_chart_5 {
    fill: #CFFFF6;
}

.custom_horizontal_bar_chart_6 {
    fill: #ABF1BC;
}

.custom_horizontal_bar_chart_7 {
    fill: #22E4AC;
}

.custom_horizontal_bar_chart_8 {
    fill: #00CC98;
}

.custom_horizontal_bar_chart_9 {
    fill: #009D74;
}
