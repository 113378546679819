﻿.custom_heatmap_high {
    fill: #FE8080;
}

.custom_heatmap_medium {
    fill: #FFFE00;
}

.custom_heatmap_low {
    fill: #ABF1BC;
}
