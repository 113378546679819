﻿.custom_pie_chart_high {
    fill: #FE8080;
}

.custom_pie_chart_medium {
    fill: #FFFE00;
}

.custom_pie_chart_low {
    fill: #ABF1BC;
}
