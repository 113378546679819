﻿.display-inline {
    display: inline
}

.important-form-btn {
    padding: 0.375rem 0.75rem !important;
    color: #FFFFFF !important;
}

.padding-5px {
    padding: 5px !important;
}

.padding-10px {
    padding: 10px !important;
}

.padding-top-bottom-30px {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 25px !important;
    padding-right: 10px !important;
}

.padding-top-bottom-only-5px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.padding-top-bottom-5px {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 25px !important;
    padding-right: 10px !important;
}

.padding-top-bottom-1px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.padding-left-right-5px {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.display-inline-block {
    display: inline-block;
}

.red-background {
    background-color: red;
}

.softred-background {
    background-color: #FE8080;
}

.yellow-background {
    background-color: #FFFE00;
}

.skyblue-background {
    background-color: #AEE7F8;
}

.white-background {
    background-color: #FFFFFF;
}

.orange-background {
    background-color: orange;
}

.green-background {
    background-color: #8CE68C;
}

.purple-background {
    background-color: purple;
}

.margin-5px {
    margin: 5px;
}

.margin-left-right-5px {
    margin-left: 5px;
    margin-right: 5px;
}

.margin-left-right-10px {
    margin-left: 10px;
    margin-right: 10px;
}

.margin-left-right-15px {
    margin-left: 15px;
    margin-right: 15px;
}

.margin-bottom-5px {
    margin-bottom: 5px;
}

.margin-top-15px {
    margin-top: 15px;
}

.square {
    display: flex;
    justify-content: center;
}

.square-content {
    width: 40px;
    height: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 5px;
    color: $theme-font-color;
}

.square-icon-button {
    align-items: center; /* Center vertically within the button */
    justify-content: center; /* Center horizontally within the button */
    width: 1.5em; /* Replace with your desired button width */
    height: 1.5em; /* Replace with your desired button height */
    padding: 0 !important;
}

.square-icon-button > i {
    margin: 0;
}

.square-icon-button-sm {
    align-items: center; /* Center vertically within the button */
    justify-content: center; /* Center horizontally within the button */
    width: 1.5em; /* Replace with your desired button width */
    height: 1.5em; /* Replace with your desired button height */
    line-height: 0 !important;
    padding: 0 !important;
}

.square-icon-button-sm > i {
    margin: 0;
    font-size: $theme-button-font-size-sm;
}

.tree-button {
    margin: 0;
    align-items: center; /* Center vertically within the button */
    justify-content: center; /* Center horizontally within the button */
    line-height: 150% !important;
    padding: 0px 5px !important;
    font-size: $theme-button-font-size !important;
}

.tree-button > i {
    margin: 0;
    font-size: $theme-button-font-size;
}

.rectangle {
    display: flex;
    justify-content: center;
}

.rectangle-content {
    width: 100px;
    height: 20px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #FFFFFF;
}

.table td, .table th {
    white-space: normal !important;
    overflow-wrap: break-word; /*Wordwrap for table*/
}

/*
.table thead th {
    vertical-align: top;
    padding: 0.5rem 10px;
}

.table tbody td {
    vertical-align: top;
}
*/

.rating-span {
    width: 100px;
    display: flex;
    padding: 5px;
    box-sizing: border-box;
    font-weight: bold;
    color: $theme-font-color;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.circle-span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
}

.circle-span-theme {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $custom-theme-font-color;
    background: $custom-theme-background-old; /* fallback for old browsers */
    background: $custom-theme-background-old-chrome; /* Chrome 10-25, Safari 5.1-6 */
    background: $custom-theme-background; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.white-font {
    color: #ffffff;
}

.green-font {
    color: #8CE68C;
}

.theme-font-color {
    color: $theme-font-color;
}

.green-border {
    border: 2px solid #8CE68C;
}

.black-border {
    border: 2px solid #000000;
}

.button-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom_center_item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom_vertical_center_item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    color: inherit;
}

.indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    color: inherit;
}

.sticky-button-content {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
}

.custom-font-size {
    font-size: $theme-font-size;
}

.custom-cursor-pointer {
    cursor: pointer;
}

.custom-cursor-move {
    cursor: move;
}

.custom-icon-size {
    height: $theme-button-font-size;
    width: $theme-button-font-size;
}

.custom-theme {
    color: $custom-theme-font-color; /* fallback for old browsers */
    background: $custom-theme-background-old; /* fallback for old browsers */
    background: $custom-theme-background-old-chrome; /* Chrome 10-25, Safari 5.1-6 */
    background: $custom-theme-background; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    h5 {
        color: $custom-theme-font-color !important; /* fallback for old browsers */

        a {
            color: $custom-theme-font-color; /* fallback for old browsers */
            font-weight: bold;
        }
    }
}

.custom-theme-outline {
    color: $custom-theme-outline-color-old; /* fallback for old browsers */
    color: $custom-theme-outline-color-old-chrome; /* Chrome 10-25, Safari 5.1-6 */
    color: $custom-theme-outline-color; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-color: $custom-theme-outline-background;
    background-image: $custom-theme-outline-background-image;
    border-color: $custom-theme-outlineborder-color-old;
    border-color: $custom-theme-outlineborder-color-old-chrome; /* Chrome 10-25, Safari 5.1-6 */
    border-color: $custom-theme-outlineborder-color; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.custom-theme:hover {
    color: $custom-theme-font-color; /* fallback for old browsers */
    background: $custom-theme-background-old-hover; /* fallback for old browsers */
    background: $custom-theme-background-old-chrome-hover; /* Chrome 10-25, Safari 5.1-6 */
    background: $custom-theme-background-hover; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    h5 {
        color: $custom-theme-font-color !important; /* fallback for old browsers */

        a {
            color: $custom-theme-font-color; /* fallback for old browsers */
            font-weight: bold;
        }
    }
}

.custom-theme-outline:hover {
    color: $custom-theme-outline-color-old-hover; /* fallback for old browsers */
    color: $custom-theme-outline-color-old-chrome-hover; /* Chrome 10-25, Safari 5.1-6 */
    color: $custom-theme-outline-color-hover; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-color: $custom-theme-outline-background-hover;
    background-image: $custom-theme-outline-background-image-hover;
    border-color: $custom-theme-outlineborder-color-old-hover;
    border-color: $custom-theme-outlineborder-color-old-chrome-hover; /* Chrome 10-25, Safari 5.1-6 */
    border-color: $custom-theme-outlineborder-color-hover; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.custom-button-font-size {
    font-size: $theme-button-font-size;
}

.btn {
    font-size: $theme-button-font-size;
}

.dropdown-menu {
    font-size: $theme-button-font-size;
}

.dropdown-menu li a {
    font-size: $theme-button-font-size;
}

.fa, .fab, .fal, .far, .fas {
    font-size: $theme-button-font-size;
}

.MuiStepper-root {
    padding: 0 !important;
}

.rct-title {
    line-height: 1.5;
    align-items: center;
    justify-content: center;
}

.rct-title .custom-theme {
    color: $custom-theme-font-color;
}

.rct-title .custom-theme-outline {
    color: $custom-theme-outline-color-old;
}

.rct-title .btn {
    color: $custom-theme-font-color;
}

.MuiTypography-body2 {
    font-size: $theme-button-font-size !important;
}

.MuiStepIcon-text {
    font-size: $theme-button-font-size !important;
}

@media only screen and (max-width: 991px) {
    .custom_vertical_center_item {
        position: static;
    }
}
