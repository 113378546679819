﻿.custom_dashboard_gradients_background_1_1 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left 0px top 0px;
}

.custom_dashboard_gradients_background_1_2 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -166px top 0px;
}

.custom_dashboard_gradients_background_1_3 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -332px top 0px;
}

.custom_dashboard_gradients_background_2_1 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left 0px top -65px;
}

.custom_dashboard_gradients_background_2_2 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -166px top -65px;
}

.custom_dashboard_gradients_background_2_3 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -332px top -65px;
}

.custom_dashboard_gradients_background_3_1 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left 0px top -130px;
}

.custom_dashboard_gradients_background_3_2 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -166px top -130px;
}

.custom_dashboard_gradients_background_3_3 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -332px top -130px;
}

.custom_dashboard_gradients_background_4_1 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left 0px top -195px;
}

.custom_dashboard_gradients_background_4_2 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -166px top -195px;
}

.custom_dashboard_gradients_background_4_3 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -332px top -195px;
}

.custom_dashboard_gradients_background_5_1 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left 0px top -260px;
}

.custom_dashboard_gradients_background_5_2 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -166px top -260px;
}

.custom_dashboard_gradients_background_5_3 {
    background: #3A8AD9; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom, #3A8AD9, #4FA9F2, #87CDF6, #AEE7F8, #CFFFF6, #ABF1BC, #22E4AC, #00CC98, #009D74); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background-size: 498px 350px;
    background-position: left -332px top -260px;
}
