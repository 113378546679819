.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;
  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;
  /* clicking anywhere will focus the input */
  cursor: text;
  &.is-focused {
    border-color: #B1B1B1;
  }
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  //background: #F1F1F1;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  &:after {
    content: '\2715';
    //color: #AAA;
    margin-left: 8px;
  }
  &:hover, &:focus {
    border-color: #B1B1B1;
  }
}

.react-tags__search {
  display: inline-block;
  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;
    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    /* match the font styles */
    font-size: inherit;
    line-height: inherit;
    &::-ms-clear {
      display: none;
    }
  }
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  }
  li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
    mark {
      text-decoration: underline;
      background: none;
      font-weight: 600;
    }
    &:hover {
      cursor: pointer;
      background: #eee;
    }
    &.is-active {
      background: #b7cfe0;
    }
    &.is-disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px;
  }
}

/* custom scss */
.react-tags {
  .react-tags__selected-tag {
    margin: 5px 10px 5px 0;
    padding: 5px 12px;
    &:after {
      //color: inherit;
    }
  }
  .react-tags__search {
    margin: 0;
    input {
      width: 100% !important;
      background: transparent !important;
      border: none;
      padding: 8px 0px;
    }
  }
  input {
    width: 100% !important;
    background: transparent !important;
    border: none;
    padding: 8px 0px;
  }
}